import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import img1 from "../Imeges/1.jpeg";
import img2 from "../Imeges/2.jpg";
import img3 from "../Imeges/3.jpg";
import img4 from "../Imeges/4.jpg";
import img5 from "../Imeges/5.jpg";
import img6 from "../Imeges/6.jpg";
import img7 from "../Imeges/7.jpg";
import img8 from "../Imeges/8.jpg";
import img9 from "../Imeges/9.jpg";
import img10 from "../Imeges/10.jpg";
import img11 from "../Imeges/pune.jpg";
import img12 from "../Imeges/punekar1.jpg";
import img13 from "../Imeges/punekar121.jpg";
import img14 from "../Imeges/punekars.jpg";


const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14];

function ExploreOurCollection() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleImages, setVisibleImages] = useState(3);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setVisibleImages(1);
      } else if (window.innerWidth < 1024) {
        setVisibleImages(2);
      } else {
        setVisibleImages(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getVisibleImages = () => {
    const visible = [];
    for (let i = 0; i < visibleImages; i++) {
      const index = (currentIndex + i) % images.length;
      visible.push(images[index]);
    }
    return visible;
  };

  return (
    <div className="relative w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="overflow-hidden">
        <div className="flex transition-transform duration-500 ease-in-out">
          {getVisibleImages().map((img, index) => (
            <div key={index} className={`w-full ${visibleImages > 1 ? 'sm:w-1/2' : ''} ${visibleImages > 2 ? 'lg:w-1/3' : ''} p-2 sm:p-4`}>
              <img
                src={img}
                alt={`Slide ${index + 1}`}
                className="w-full h-64 sm:h-80 lg:h-128 object-cover rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-6 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-all"
      >
        <ChevronLeft size={24} />
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-6 transform -translate-y-1/2 p-2 bg-black bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-all"
      >
        <ChevronRight size={24} />
      </button>
    </div>
  );
}

export default ExploreOurCollection;